import styled from 'styled-components';
import Typography from 'components/UI/Typography';

export const HomeWrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    margin-top: 80px;
  }

  @media (min-width: 1680px) {
    margin-top: 0px;
  }
`;

export const HomeBodyWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
  padding-left: 13px;
  width: 159px;
  overflow: hidden;
  opacity: 0;

  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    padding-left: 15px;
    width: 210px;
  }
`;

export const HomeBodyLine = styled.hr`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 3px;
  height: 100%;
  background-color: ${(props) => props.theme.colors.lime};
  @media (min-width: ${(props) => props.theme.breakpoints.s}) {
    width: 4px;
  }
`;

export const HomeBody = styled.h1`
  color: ${(props) => props.theme.colors.white};
`;

export const HomeBodyText = styled(Typography)``;

export const SiteLinksWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  opacity: 0;

  > *:first-of-type {
    margin-top: 0;
  }
`;

export const SiteLinkWrapper = styled.li`
  margin: 0;
  padding: 0;
`;
