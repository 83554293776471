import React from 'react';
import PropTypes from 'prop-types';
import { TypographyStyled } from './styles';

const Typography = ({
  children,
  className,
  style,
  theme,
  as,
  dangerouslySetInnerHTML,
  onClick,
}) => {
  if (!as) {
    if (
      theme === 'h1' ||
      theme === 'h2' ||
      theme === 'h3' ||
      theme === 'h4' ||
      theme === 'h5' ||
      theme === 'h6'
    ) {
      as = theme;
    } else {
      as = 'p';
    }
  }

  const typographyProps = {
    as,
    style: style ? style : null,
    className: `${className ? className : ''} Typography ${theme ? theme : ''}`,
    theme,
    onClick: onClick ? onClick : null,
  };

  return dangerouslySetInnerHTML ? (
    <TypographyStyled
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      {...typographyProps}
    />
  ) : (
    <TypographyStyled {...typographyProps}>{children}</TypographyStyled>
  );
};

Typography.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.oneOf([
    'article-body',
    'eyebrow-text',
    'body-s',
    'body',
    'body-l',
    'body-xl',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
  ]),
  as: PropTypes.string,
  dangerouslySetInnerHTML: PropTypes.any,
  children: PropTypes.any,
  onClick: PropTypes.func,
};

export default Typography;
